<template>
  <div class="FlipContainer">
    <!-- <slot/> -->

    <div
      class="flipper"
      :class="{ flipped }">
      <slot name="front" />
      <slot name="back" />
      <!-- <div class="front">Front</div> -->
      <!-- <div class="back">Back</div> -->
    </div>
  </div>
</template>

<script>
export default {
    props: {
        flipped: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // flipped: false
        };
    },
    mounted() {},
    beforeDestroy() {},
    methods: {
        // flip () {
        //   this.flipped = !this.flipped
        // }
    }
};
</script>
<style lang="scss" scoped>
.FlipContainer {
    // padding: 2em;
    // background-color: black;
    // height: 100%;
    perspective: 1000px;
    // border: 1px solid green;
}

.flipper {
    transition: transform 1s;
    transform-style: preserve-3d;

    height: 100%;

    position: relative;
    // width: 100%;
    //
    &.flipped {
        transform: rotateY(180deg);
    }

    & > * {
        backface-visibility: hidden;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background: white;
    }

    & > *:last-child {
        transform: rotateY(180deg);
    }
}

// .front, .back {
//   backface-visibility: hidden;
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//
//   background: white;
// }

// .back {
//   transform: rotateY(180deg);
// }
</style>
